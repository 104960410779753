import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from '../styles/utils'

import { Layout, PageHeader, Block } from '../components'
import { container, padding } from '../styles/global'

const DefaultPage = props => {
    const { data } = props.pageContext
    const breadcrumbs = {
        heading: {
            title: 'Strive Financial',
            link: '/',
        },
        description: {
            title: data?.title,
            link: undefined,
        },
    }

    const renderBlocks = () => {
        if (!data.blocks) return

        return data.blocks.map((block, i) => {
            return (
                <Block
                    key={i}
                    index={i}
                    layout={block.acf_fc_layout}
                    {...block}
                />
            )
        })
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            <Wrapper>
                <Container>{renderBlocks()}</Container>
            </Wrapper>
        </Layout>
    )
}

const Wrapper = styled.div`
    ${tw`bg-white`};
`

const Container = styled.div`
    ${container}
    ${padding}

	.tiny-mce {
		width: 640px;
		max-width: 100%;
        padding-top: 4rem;
        padding-bottom: 4rem;

        h2,
        h3,
        h4 {
			font-weight: 600;
    		font-size: 1.375rem;
    		line-height: 1.24;

            &:not(:first-child) {
                margin-top: 3rem;
            }
        }
        p,
        hr {
            &:not(:first-child) {
                margin-top: 2rem;
            }
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 1.5rem;

            ul {
                list-style-type: circle;
                margin-block-start: 0;
                padding-inline-start: 3rem;
            }

            li {
            }
        }

        a {
            text-decoration: underline;
        }
    }
`

export default DefaultPage

import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

import DefaultPage from '../templates/default-page'
import Home from '../pages/index'
import About from '../pages/about'
import Brokers from '../pages/brokers'
import Contact from '../pages/contact'
import Loans from '../pages/business-loans'
import Loan from '../templates/business-loan'
import Investments from '../templates/investments-listing'
import Investment from '../templates/investments-single'
import NewsListing from '../templates/news-listing'
import NewsSingle from '../templates/news-single'


const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
    const postID = useSearchParam('id');
    
	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;
                // console.log('preview data',data);
                // console.log('post type ',data.post_type);
				setData(data)
				setPostType(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
            'default': DefaultPage,
            'home': Home,
            'about': About,
            'contact': Contact,
            'partners-listing': Brokers,
            'loans-listing': Loans,
            'loans': Loan,
            'investments': Investment,
            'investments-listing': Investments,
            'news': NewsSingle,
            'news-listing': NewsListing, 
		}
		
        const Component = templates[postType];
        
		return (
			<Component
				previewData={data.acf}
                pageContext={{
					data: {
						...data,
						...data.acf
					}
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

